<template>
  <!-- Order details step 1 form START -->
  <section id="orderStart">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-container>
        <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
        <div v-if="isLoading" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else>
          <Step-Wizard :step="1"></Step-Wizard>
        </div>
        <b-card v-if="getAppSettings.debug">
          <pre
            >{{ this.order }}
                </pre
          >
        </b-card>

        <!-- Search START -->
        <b-card
          v-if="getAppSettings.useProjectNo && getAppSettings.useProjectSearch"
        >
          <h4>Search projects</h4>
          <hr />
          <b-tooltip target="searchProjects"
            >Use this to quicly find your project</b-tooltip
          >
          <div class="col-lg-12" id="searchProjects">
            <vue-bootstrap-typeahead
              v-model="CurrentProjectname"
              placeholder="Search projects (start typing)"
              :data="allProjects"
              size="sm"
              :minMatchingChars="1"
              :serializer="project => project.name"
              @hit="setPro($event.id)"
              class=""
            />
            <br />
          </div>
        </b-card>
        <!-- Search END -->

        <!-- Project card details START -->

        <b-card>
          <b-container class="m-2">
            <h4>Project details</h4>
            <hr />
            <b-alert v-if="valiationErrorMessage" show variant="danger">{{
              valiationErrorMessage
            }}</b-alert>

            <b-row>
              <b-col v-if="getAppSettings.useProjectNo">
                <b-form-group>
                  <label><i class="fas fa-sitemap"></i> Project no</label>
                  <b-input type="number" v-model="order.projectNo"></b-input>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group>
                  <b-tooltip target="projectName"
                    >Add project name and or project nr</b-tooltip
                  >
                  <label><i class="fas fa-sitemap"></i> Project *</label>
                  <b-input
                    type="text"
                    id="projectName"
                    v-model="order.projectName"
                    required
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <b-row>
                            <b-col>
                                <b-form-group>
                                    <label><i class="fas fa-sticky-note"></i> Description</label>
                                    <b-form-textarea v-model="description" rows="3"
                                        max-rows="6"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row> -->
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="sameContact"
                    name="checkbox-1"
                    value="false"
                    unchecked-value="true"
                    required
                  >
                    Contact person <strong>on-site</strong> is the one who
                    orders
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="sameContact == 'true'">
              <b-col cols="12" md="6">
                <b-form-group>
                  <label
                    ><i class="fas fa-address-book"></i> Contact person
                    (on-site) *
                  </label>
                  <b-input
                    type="text"
                    v-model="order.contanctPersonOnSite"
                    placeholder="Enter contact person"
                    required
                    :readonly="sameContact == 'false'"
                  ></b-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group>
                  <label
                    ><i class="fas fa-envelope-open-text"></i> Phone (on-site)
                    *</label
                  >
                  <b-input
                    type="text"
                    v-model="order.contactPersonOnSitePhone"
                    :readonly="sameContact == 'false'"
                    required
                    placeholder="Enter phone number"
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="sameContact == 'true'">
              <b-col cols="12" md="6">
                <b-form-group>
                  <label><i class="fas fa-sticky-note"></i> Comments</label>
                  <b-form-textarea
                    v-model="order.contactPersonOnSiteComments"
                    rows="3"
                    max-rows="6"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6" md="6">
                <b-form-group>
                  <label
                    ><i class="fas fa-address-book"></i> Contact person
                  </label>
                  <b-input
                    type="text"
                    v-model="order.orderedBy"
                    placeholder=""
                    :readonly="overrideContact === 'false'"
                  ></b-input>
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="overrideContact"
                    class="mt-2"
                    name="checkbox-2"
                    value="false"
                    v-if="getUser.isSuperUser"
                    unchecked-value="true"
                  >
                    Change contact person
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="6">
                <b-form-group>
                  <label
                    ><i class="fas fa-envelope-open-text"></i> Phone *</label
                  >
                  <b-input
                    type="text"
                    v-model="order.phone"
                    required
                    placeholder="Phone"
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" md="6">
                <b-form-group>
                  <label
                    ><i class="fas fa-envelope-open-text"></i> Email *</label
                  >
                  <b-input
                    type="text"
                    v-model="order.mail"
                    required
                    placeholder="Email Address"
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
        <!-- Project card details END -->

        <b-card>
          <b-container>
            <b-row>
              <b-col>
                <h4>
                  <i class="mr-1 fas fa-calendar-alt"></i> Delivery details
                </h4>
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <b-tooltip target="streetName">For delivery</b-tooltip>
                  <label
                    ><i class="fas fa-map-marker-alt"></i> Street name*</label
                  >
                  <b-form-input
                    id="streetName"
                    type="text"
                    required
                    v-model="order.deliveryAddress.streetName"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-tooltip target="deliverydate"
                  >Preferred delivery date</b-tooltip
                >
                <b-form-group>
                  <label
                    ><i class="fas fa-calendar-alt"></i> Delivery date *</label
                  >
                  <br />
                  <div id="deliverydate">
                    <date-range-picker
                      ref="picker"
                      required
                      :locale-data="{ firstDay: 1 }"
                      :singleDatePicker="true"
                      :min-date="minDate"
                      :timePicker="false"
                      :showWeekNumbers="false"
                      v-model="dateRange"
                      @update="updateDateRange"
                      :show-dropdowns="false"
                      :linkedCalendars="false"
                      :autoApply="true"
                      :ranges="false"
                    >
                      <div slot="input" style="min-width:150px;">
                        {{ preferredDeliveryDate }}
                      </div>
                    </date-range-picker>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="5" md="6">
                <b-form-group>
                  <label
                    ><i class="fas fa-map-marked-alt"></i> Zip code *</label
                  >
                  <b-form-input
                    id="postalCode"
                    type="text"
                    required
                    v-model="order.deliveryAddress.postalCode"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="7" md="6">
                <b-form-group>
                  <label><i class="fas fa-city"></i> City *</label>
                  <b-form-input
                    id="city"
                    type="text"
                    required
                    v-model="order.deliveryAddress.city"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="addComments !== 'true'">
              <b-col>
                <b-form-group>
                  <b-form-checkbox
                    id="checkbox-comments"
                    v-model="addComments"
                    name="checkbox-comments"
                    value="true"
                    unchecked-value="false"
                  >
                    Add comments
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="addComments === 'true'">
              <b-col>
                <b-form-group>
                  <label><i class="fas fa-sticky-note"></i> Notes</label>
                  <b-form-textarea v-model="order.notes" rows="3" max-rows="6">
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card>

        <section>
          <Back-Button></Back-Button>
          <b-button variant="primary" type="submit" class="finish"
            >Next</b-button
          >
        </section>
      </b-container>
    </b-form>
  </section>
  <!-- Order details step 1 form END -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import StepWizard from "@/components/StepWizard";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import DateRangePicker from "vue2-daterange-picker";
import BackButton from "@/components/Buttons/BackButton";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "StepOne",
  components: {
    StepWizard,
    VueBootstrapTypeahead,
    DateRangePicker,
    BackButton
  },
  data() {
    return {
      error: null,
      isLoading: true,
      valiationErrorMessage: null,
      CurrentProjectname: "",
      sameContact: "false",
      overrideContact: "false",
      addComments: false,
      show: true,
      showProjectSearch: true,
      showProject: true,
      query: "",
      orderId: null,
      dateRange: {},
      order: {
        projectId: null,
        projectNo: null,
        projectName: "",
        customer: "",
        description: "",
        dueDate: "0001-01-01T00:00:00",
        shippingDate: "0001-01-01T00:00:00",
        orderDate: "0001-01-01T00:00:00",
        preferredDeliveryDate: moment(
          new Date().setDate(new Date().getDate() + 7)
        ).format("YYYY-MM-DD"),
        deliveryDate: "0001-01-01T00:00:00",
        receivedDate: "0001-01-01T00:00:00",
        deliveryAddress: {
          streetName: "",
          postalCode: "",
          city: "",
          country: "",
          latitude: null,
          longitude: null
        },
        contanctPersonOnSite: null,
        contactPersonOnSitePhone: null,
        contactPersonOnSiteComments: null,
        orderedBy: null,
        phone: null,
        mail: null,
        address: null,
        statusNo: "10",
        statusName: "New",
        notes: null,
        orderLines: [],
        createdBy: null,
        modifiedBy: null
      },
      item: {},
      streetName: "",
      step: 1,
      validated: false,
      minDate: moment(new Date()).format("YYYY-MM-DD")
    };
  },
  mounted() {
    this.getProjects();
    this.orderId = this.$route.query.id || null;

    if (this.orderId) {
      const self = this;
      this.loadOrder(this.orderId)
        .then(r => {
          if (r.data.statusNo !== "1") {
            //if status any other than 10 it's sendt and the admin must handle it form EDIT form.
            self.$router.push(`/order/view/${this.orderId}`);
          }
          this.order = r.data;
          this.isLoading = false;
        })
        .catch(e => {
          self.error = "Failed when loading order: " + e;
        });
    }

    if (!this.order.createdBy) {
      this.order.orderedBy = `${this.getUser.firstName} ${this.getUser.lastName}`;
      this.order.createdBy = this.getUser.id.toString();
    }

    if (!this.order.phone) {
      this.order.phone = this.getUser.phone || "";
    }
    if (!this.order.mail) {
      this.order.mail = this.getUser.email || "";
    }

    this.dateRange.startDate = this.preferredDeliveryDate;
    this.isLoading = false;
  },
  methods: {
    ...mapActions(["getProjects", "loadOrder", "updateCreateCurrentOrder"]),
    onSubmit(evt) {
      evt.preventDefault();

      this.isLoading = true;
      window.scrollTo(0, 0);
      this.valiationErrorMessage = null;
      if (!this.order.projectName || this.order.projectName === "") {
        this.valiationErrorMessage = "Missing project";
        // this.$bvToast.toast(`Missing project`, {
        //     title: 'Validation failed',
        //     autoHideDelay: 50000,
        //     variant: "warning",
        //     appendToast: false
        // });
        this.isLoading = false;
      } else {
        let self = this;
        // Default values to current logged in user
        if (!this.order.contanctPersonOnSite) {
          this.order.contanctPersonOnSite = `${this.getUser.firstName} ${this.getUser.lastName}`;
        }
        if (!this.order.contactPersonOnSitePhone) {
          this.order.contactPersonOnSitePhone = this.getUser.phone || "";
        }

        this.$store
          .dispatch("updateCreateCurrentOrder", this.order)
          .then(r => {
            if (!this.orderId && r.data.id) {
              this.orderId = r.data.id;
            }
            self.$router.push({
              path: "/order/new/2",
              query: {
                id: this.orderId
              }
            });
            self.isLoading = false;
          })
          .catch(e => {
            self.error = e;
            self.isLoading = false;
          });
      }

      this.$router.push({ query: { id: this.orderId } });
      this.isLoading = false;
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.order.projectId = null;
      this.order.projectName = "";
      this.order.phone = "";
      this.order.mail = "";
      this.order.contanctPersonOnSite = "";
      this.order.contactPersonOnSitePhone = "";
      this.order.contactPersonOnSiteComments = "";
      this.preferredDeliveryDate = moment(
        new Date().setDate(new Date().getDate() + 7)
      ).format("YYYY-MM-DD");
      this.order.deliveryAddress.streetName = "";
      this.order.deliveryAddress.postalCode = "";
      this.order.deliveryAddress.city = "";
      this.order.notes = "";
      // this.form.email = ''
      // this.form.name = ''
      // this.form.food = null
      // this.form.checked = []
      // // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    setPro(id) {
      //This sets the current active projects and loads contact details
      if (id) {
        this.order.projectId = id;

        this.order.projectName = this.allProjects
          .filter(x => x.id === id)
          .map(x => {
            return x.name;
          })[0];
        this.order.projectNo = this.allProjects
          .filter(x => x.id === id)
          .map(x => {
            return x.projectNo;
          })[0];
      }
    },
    updateDateRange(value) {
      if (value) {
        this.preferredDeliveryDate = value.startDate;
      }
    },
    cancel() {
      this.$router.go(-1);
    }
  },
  computed: {
    ...mapGetters(["allProjects", "getAppSettings", "getUser"]),
    preferredDeliveryDate: {
      get() {
        return moment(String(this.order.preferredDeliveryDate)).format(
          "YYYY-MM-DD"
        );
      },
      set(value) {
        this.order.preferredDeliveryDate = value;
      }
    }
  }
};
</script>

<style></style>

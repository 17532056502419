<template>
  <!-- Wizard START -->
  <section class="card">
    <header class="card-header">
      New order <b-badge class="float-right ml-2">{{ orderName }}</b-badge>
      <div class="float-right">step:{{ step }}</div>
    </header>
    <div class="card-body">
      <div class="stepy-tab">
        <ul id="default-titles" class="stepy-titles clearfix">
          <li
            id="default-title-0"
            v-bind:class="{ 'current-step': step === 1 }"
            @click="set_step(1)"
          >
            <div>Step 1</div>
          </li>
          <li
            id="default-title-1"
            v-bind:class="{ 'current-step': step === 2 }"
            @click="set_step(2)"
          >
            <div>Step 2</div>
          </li>
          <li
            id="default-title-2"
            v-bind:class="{ 'current-step': step === 3 }"
            @click="set_step(3)"
          >
            <div>Step 3</div>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!-- Wizard END -->
</template>

<script>
export default {
  name: "StepWizard",
  props: ["orderName", "step"],
  data() {
    return {
      // step: 1
    };
  },
  methods: {
    set_step(step) {
      // this.step = step;
      if (this.$route.query.id) {
        var orderId = this.$route.query.id;
        this.$router.push({
          path: "/order/new/" + step,
          query: {
            id: orderId
          }
        });
      }
    }
  },
  created() {}
};
</script>

<style></style>
